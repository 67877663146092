<template>
    <div class="payment">
        <DiscountCodeSection class="mb-32" />
        <DiscountPointSection class="mb-32" :price="prices?.priceWithoutDiscount" />
        <Payment
            :check-out="checkOut"
            :is-loading="isLoading"
            :is-success-modal-opened="isSuccessModalOpened"
            :is-check-out-free="isCheckOutFree"
            @changeSuccessModalValue="changeSuccessModalValue"
            @completeOrder="completeOrder"
            @setCheckOut="setCheckOut"
        />
        <SuccessModal
            @close="isPriceChangeModalOpened = !isPriceChangeModalOpened"
            v-if="isPriceChangeModalOpened"
            :title="$t('order.orderPriceHasChanged')"
            :description="$t('order.orderPriceHasChangedDescription')"
        />
    </div>
</template>

<script>
    import SuccessModal from '@/components/Modals/SuccessModal';
    import Payment from '@/components/Order/Payment.vue';
    import DiscountCodeSection from '@/components/Cart/DiscountCodeSection.vue';
    import DiscountPointSection from '@/components/Cart/DiscountPointSection.vue';
    import { mapActions, mapGetters } from 'vuex';
    import { OrderApi, MealKitApi, AdditionApi, AllergenApi } from '@/api';
    import { PaymentTypeEnum, GoogleAnalyticsEnum } from '@/utils/enums';
    import { TYPE } from 'vue-toastification';
    import { ConversionEventNameEnum } from '@/utils/enums';

    export default {
        name: 'PaymentPage',
        components: {
            DiscountCodeSection,
            DiscountPointSection,
            Payment,
            SuccessModal,
        },
        data() {
            return {
                isLoading: false,
                isSuccessModalOpened: false,
                isPriceChangeModalOpened: false,
            };
        },
        watch: {
            isCheckOutFree: {
                handler(val) {
                    if (val) {
                        this.setPaymentType(PaymentTypeEnum.CASH);
                    }
                },
                immediate: true,
            },
        },
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('cart', ['cart', 'discountCodes']),
            ...mapGetters('checkOut', ['paymentType', 'paymentData', 'checkOut']),
            ...mapGetters('orderInformation', ['deliveryInformation', 'buyerInformation']),
            ...mapGetters('orderPrices', ['prices']),
            ...mapGetters('discountPoint', ['appliedDiscountPoints']),

            isCheckOutFree() {
                return Number(this.prices?.totalPrice) === 0;
            },
        },
        methods: {
            ...mapActions('checkOut', ['setCheckOut', 'setPaymentType']),
            ...mapActions('cart', ['updateMealKitInCart']),

            changeSuccessModalValue(val) {
                this.isSuccessModalOpened = val;
            },

            async completeOrder() {
                try {
                    this.isLoading = true;
                    const data = this.prepareDataForRequest();


                    const response = await OrderApi.store(data);

                    // console.log(response);

                    if (this.paymentType === PaymentTypeEnum.MONTONIO) {
                        window.location.href = response.url;
                    } else {
                        this.setCheckOut(response.checkOut);
                        this.isSuccessModalOpened = true;
                        this.$filters.toast(this.$t('orderManagement.statuses.new'), { type: TYPE.SUCCESS });
                    }

                    let GaformatedOrdersData = response.checkOut.orders.map((order) => ({ 
                        item_id: order.id,
                        item_name: order.mealKit.name.lt,
                        quantity: 1,
                        price: order.prices.meal_kit_price,
                        discount: order.prices.discount_from_codes,
                    }));


                    this.$gtag.event(GoogleAnalyticsEnum.PURCHASE, {
                        currency: 'EUR',
                        transaction_id: response.checkOut.merchantReference,
                        value: response.checkOut.prices.total_price,
                        items: GaformatedOrdersData,
                    });

           
                    fbq('track', ConversionEventNameEnum.PURCHASE, {
                        currency: 'EUR',
                        value: response.checkOut.prices.total_price,
                        transaction_id: response.checkOut.id,
                        contents: response.checkOut.orders,
                    })

                } catch (error) {
                    Object.entries(error.errors).forEach(async (element) => {
                        this.$filters.toast(element[1][0]);

                        if (element[0].includes('Prices')) {
                            const cartItemIndex = element[0].replace(/[^0-9]/g, '');
                            const needCartItem = this.cart[cartItemIndex];
                            const needCartItemCopy = JSON.parse(JSON.stringify(needCartItem));

                            try {
                                await this.updatePrices(needCartItemCopy);
                            } catch (error) {
                                this.$filters.toast(error.message);
                            }
                        }
                    });
                } finally {
                    this.isLoading = false;
                }
            },

            async updatePrices(needCartItem) {
                try {
                    const response = await MealKitApi.show(needCartItem.mealKit.id);

                    if (!response.data.isUnique) {
                        const newCalorie = response.data.calories.find(
                            (calorie) => calorie.id === needCartItem.calorie.id
                        );
                        needCartItem.calorie = newCalorie;

                        this.updateAllergenOrAdditions(needCartItem, 'additions', AdditionApi);
                        this.updateAllergenOrAdditions(needCartItem, 'allergens', AllergenApi);
                    }

                    needCartItem.mealKit = response.data;
                    this.updateMealKitInCart(needCartItem);

                    this.isPriceChangeModalOpened = true;

                    this.$filters.toast(this.$t('common.pricesWasUpdated'), { type: TYPE.SUCCESS });
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            updateAllergenOrAdditions(cartItem, state, Api) {
                cartItem[state].forEach(async (element, index) => {
                    try {
                        const newElement = await Api.show(element.id);
                        cartItem[state][index] = newElement.data;
                        this.updateMealKitInCart(cartItem);
                    } catch (error) {
                        this.$filters.toast(error.message);
                    }
                });
            },

            prepareDataForRequest() {
                // orders
                const orders = this.cart.map((cartItem) => {
                    const discountFromCodesPriceArray = cartItem.appliedDiscountCodes.map((code) => code.price);
                    const discountFromCodesPrice = discountFromCodesPriceArray.reduce(
                        (partialSum, a) => partialSum + a,
                        0
                    );

                    const kitPercentageFromWholeOrder = (
                        (Number(cartItem.totalPrice) * 100) /
                        Number(this.prices.priceWithoutDiscount)
                    ).toFixed(2);

                    const discountFromPoints = (
                        (Number(kitPercentageFromWholeOrder) * Number(this.prices.discountFromPoints)) /
                        100
                    ).toFixed(2);

                    const data = {
                        amount: cartItem.amount,
                        mealKitId: cartItem.mealKit.id,
                        prices: {
                            mealKitPrice: cartItem.mealKitPrice,
                            totalPrice: cartItem.totalPrice,
                            discountFromCodes: discountFromCodesPrice.toFixed(2),
                            discountFromPoints: discountFromPoints,
                        },
                    };

                    if (cartItem.mealKit.isUnique) {
                        return data;
                    } else {
                        return {
                            ...data,
                            periodId: cartItem.period.id,
                            menuTypeId: cartItem.menuType.id,
                            combinationId: cartItem.combination.id,
                            calorieId: cartItem.calorie.id,

                            additionsIds: cartItem.additions.map((addition) => addition.id),
                            allergensIds: cartItem.allergens.map((allergen) => allergen.id),
                            additionalAllergen: cartItem.additionalAllergen,
                        };
                    }
                });

                // Delivery Information
                this.deliveryInformation.price = this.prices.deliveryPrice;

                // Discount codes
                const discountCodesIds = this.discountCodes.map((discountCode) => discountCode.id);

                // User
                const userData = this.buyerInformation.userData;
                userData.userId = this.user ? this.user.id : null;

                // Delivery Address
                const deliveryAddress = this.buyerInformation.addressData;
                deliveryAddress.cityId = this.buyerInformation.cityData.id;
                delete deliveryAddress.name;

                return {
                    orders,
                    deliveryInformation: this.deliveryInformation,
                    discountCodesIds,
                    userData,
                    deliveryAddress,
                    discountPoints: this.appliedDiscountPoints,
                    prices: this.prices,
                    paymentableType: this.paymentType,
                    paymentable_data: this.paymentData,
                };
            },
        },
    };
</script>

<style lang="scss" scoped>
    .payment {
        width: 100%;

        .payment-item {
            h4 {
                margin-bottom: 18px;
            }

            .radio {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .btn-section {
            @include row-align-center-justify-between;

            margin-top: 24px;
        }
    }
</style>
